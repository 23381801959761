<template>
  <div>
    <!-- pc -->
    <div class="pc container">
      <div class="h5 main" style="padding:40px 0 28px 0">내 정보 수정</div>
      <div class="panel-container">
        <div class="grid" >
          <div class="subtitle5 main" style="line-height: 48px">이메일</div>
          <div class="body3 main" style="line-height: 48px">{{ user.email }}</div>
        </div>
        <div v-if="user.kakao_id || user.naver_id || user.google_id || user.facebook_id"
          class="grid">
          <div class="subtitle5 main">연동된 SNS 계정</div>
          <div class="sns flex-align" style="gap:12px">
            <img v-if="user.kakao_id" src="/static/icon/ic_kakao_circle.svg">
            <img v-if="user.naver_id" src="/static/icon/ic_naver_circle.svg">
            <img v-if="user.facebook_id" src="/static/icon/ic_facebook_circle.svg">
            <img v-if="user.google_id" src="/static/icon/ic_google_circle.svg">
          </div>
        </div>
        <div class="grid" v-for="(form,idx) in formData" :key="'form-'+idx">
          <div class="subtitle5 main" style="line-height: 48px">{{ form.label }}</div>
          <lp-text :key="'form-text-'+idx" v-bind.sync="form"></lp-text>
        </div>
        <div class="grid">
          <div class="subtitle5 main" style="line-height: 48px">등록된 카드정보</div>
          <div class="flex-between">
            <div class="body3 main">{{ cardInfo }}</div>
            <button class="button is-gray" style="width:95px;height:36px" @click="vUserBill=true">카드 관리</button>
          </div>
        </div>
        <div class="grid">
          <div class="subtitle5 main">추천인 코드</div>
          <div>
            <div class="flex-between">
              <div class="body3-bold main">{{ user.recommend_code }}</div>
              <button class="button is-dark" style="width:180px;height:36px" @click="clickShare">공유하기</button>
            </div>
            <div class="box-event">
              <div class="flex"><div class="tag">⚡️Event</div></div>
              <div class="body3 main">친구 초대하면 30,000원 할인쿠폰을 드립니다</div>
            </div>
            <!-- <div class="body5 sub3" style="margin-top:8px">*추천인 쿠폰은 1회만 발급됩니다.</div> -->

          </div>
        </div>
      </div>
      <div style="width:100%;text-align: center;margin-top:56px">
        <button class="button is-primary" style="width:242px" @click="clickUpdate">수정</button>
        <div class="size-12 sub2 unselect" @click="routerPush('password')"
             style="text-decoration:underline;margin-top:16px">비밀번호 변경하기
        </div>
      </div>
  </div>
    <!-- mobile -->
    <div class="mobile container">
    <div class="h7 main" style="padding:24px 0 16px 0">내 정보 수정</div>
    <div class="panel-container">
      <div style="width:100%">
        <div class="subtitle7 main margin-bottom-8">이메일</div>
        <div class="body4 main">{{ user.email }}</div>
        <div class="divider"></div>
        <div v-if="user.kakao_id || user.naver_id || user.google_id || user.facebook_id">
          <div class="subtitle7 main margin-bottom-8">연동된 SNS 계정</div>
          <div class="sns flex-align" style="gap:12px">
            <img v-if="user.kakao_id" src="/static/icon/ic_kakao_circle.svg">
            <img v-if="user.naver_id" src="/static/icon/ic_naver_circle.svg">
            <img v-if="user.facebook_id" src="/static/icon/ic_facebook_circle.svg">
            <img v-if="user.google_id" src="/static/icon/ic_google_circle.svg">
          </div>
        </div>
        <div class="divider"></div>
        <div v-for="(form,idx) in formData" :key="'form-'+idx">
          <div class="subtitle7 main" style="margin-bottom:8px">{{ form.label }}
          </div>
          <lp-text style="margin-bottom:12px" :key="'form-text-'+idx" v-bind.sync="form"></lp-text>
          <div class="divider"></div>
        </div>
        <div class="subtitle7 main" style="margin-bottom:8px">등록된 카드정보</div>
        <div class="flex-between">
          <div class="body4 main">{{ cardInfo }}</div>
          <button class="button is-gray body4-medium" style="width:95px;height:36px" @click="vUserBill=true">카드 관리</button>
        </div>
        <div class="divider"></div>
        <div class="subtitle7 main" style="margin-bottom:8px">추천인 코드</div>
        <div class="flex-between">
          <div class="body2-bold main">{{ user.recommend_code }}</div>
          <button class="button is-dark body4-medium" style="width:95px;height:36px" @click="clickShare">공유하기</button>
        </div>
        <div class="box-event">
          <div class="flex"><div class="tag">⚡️Event</div></div>
          <div class="body3 main" style="margin-top:4px">친구 초대하면,<br>호스팅 1개월 무료 쿠폰을 드립니다</div>
        </div>
        <!-- <div class="body5 sub3" style="margin-top:8px">*추천인 쿠폰은 1회만 발급됩니다.</div> -->
      </div>
      <div style="width:100%;text-align: center;margin-top:32px">
        <button class="button is-primary body4-medium" style="height:48px;width:100%" @click="clickUpdate">수정</button>
        <div class="size-12 sub2 unselect" @click="routerPush('password')"
             style="text-decoration:underline;margin-top:16px">비밀번호 변경하기
        </div>
      </div>
    </div>
  </div>
    <user-billing-modal v-if="vUserBill" @close="onCloseBilling"></user-billing-modal>
    <share ref="share" :sharedData="sharedData"></share>
  </div>
</template>

<script>
  import LpText from "../component/LpText";
  import FormMixin from "../../mixins/FormMixin";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import UserBillingModal from "../modal/UserBillingModal";
  import Share from "../component/Share";

  export default {
    name: "Profile",
    components: {Share, UserBillingModal, LpText},
    mixins: [
      FormMixin,
      UserAPIMixin
    ],
    created() {
      this.init();
    },
    data() {
      return {
        vUserBill: false,
        sharedData: {
          name: '',
          desc: '',
          link: '',
          img: ''
        },
        formData: [
          {
            component_name: 'lp-text',
            type: 'text',
            label: '이름',
            placeholder: '이름을 입력해주세요',
            field: 'name',
            value: '',
            maxLength: 20,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 20,
              min_length: 1,
              valid_required: true,
              required: true
            },
            errorMsg: ''
          },
          {
            component_name: 'lp-text',
            label: '휴대폰번호',
            placeholder: '휴대폰번호를 입력해주세요',
            type: 'phone',
            field: 'phone',
            value: '',
            maxLength: 13,
            errorMsg: '',
            validate: {
              valid_required: true
            }
          },
          {
            component_name: 'lp-text',
            label: '현재 비밀번호',
            placeholder: '비밀번호를 입력해주세요',
            type: 'password',
            field: 'password',
            value: '',
            maxLength: 128,
            validate: {
              valid_required: true
            },
            errorMsg: ''
          },
        ],
        cardInfo: ''
      }
    },
    methods: {
      onCloseBilling() {
        this.vUserBill=false;
        this.setCardInfo();
      },
      init() {
        this.formData[0].value = this.user.name;
        this.formData[1].value = this.user.phone;
        if(this.user.username.indexOf('$')>-1) {
          this.formData = this.formData.filter(i => i.field !== 'password');
        }
        this.setCardInfo();
        this.sharedData = {
          name: '플랫폼파이',
          desc: '개발 지식 없이도 경제적인 투자 비용으로, 플랫폼 기획 / 디자인 / 웹 & 앱개발 모두 가능!',
          link: `https://platformfy.com/home?recm=${this.user.recommend_code}`,
          img: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/non-path/kakao_og_image.png'
        };
      },
      clickShare() {
        this.$refs.share.open();
      },
      setCardInfo() {
        this.request.user.get('launchpack/card_bill').then(res => {
          let name = '등록된 카드가 없습니다';
          if (res.data.length > 0) {
            let card = res.data[0];
            name = `${card.card_name} ${card.num}`;
            if (res.data.length > 1) {
              name += `외 ${res.data.length - 1}건`;
            }
          }

          this.cardInfo = name;
        });
      },
      clickUpdate() {
        let params = this.getParamData(this.formData);
        if (!params.enable) {
          return;
        }
        if(this.user.username.indexOf('$')>-1) {
          this.request.user.patch('', params.params).then((res) => {
            this.$store.commit('setUser', res.data.user);
            this.setAuthHeader();
            this.toast('수정되었습니다.');
            this.routerPush('my_service');
          });
        } else {
          this.$axios.post('auth/user/login', {
            username: this.user.username,
            password: params.params.password
          }).then(() => {
            this.request.user.patch('', params.params).then((res) => {
              this.$store.commit('setUser', res.data.user);
              this.setAuthHeader();
              this.toast('수정되었습니다.');
              this.routerPush('my_service');
            });
          }).catch(() => {
            this.toast('비밀번호가 맞지 않습니다.');
          });
        }
      },

    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .panel-container
    display flex
    justify-content center
    flex-wrap wrap

  .grid
    grid-template-columns 172px 1fr
    grid-gap 16px
    width 100%
    border-bottom 1px solid $gray2
    padding-bottom 16px
    margin-bottom 16px

  .box-event
    margin-top 8px
    background-color $subcolor50
    padding 10px 12px
    gap 8px
    .tag
      background-color black
      color white
      font-size 12px
      padding 2px 4px

  .pc
    .box-event
      display flex

  .divider
    border-bottom 1px solid $gray2
    padding-bottom 16px
    margin-bottom 16px

  .sns
    img
      width 24px
</style>
