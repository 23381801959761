<template>
<div @click="clickShare" class="unselect">
  <img src="/static/icon/ic_band_circle.svg">
  <div class="size-12 sub1 text-center margin-top-8">밴드</div>
</div>
</template>

<script>

export default {
  name: "BtnShareBand",
  props: {
    shareData: {
      type: Object,
      required: false
    }
  },
  methods: {
    shareNaverBand(url, content) {
      let body = encodeURIComponent(this.literal`${content}\n${url}`);
      let route = encodeURIComponent(window.location.href);
      let link = this.literal`http://band.us/plugin/share?body=${body}&route=${route}`;
      window.open(link, 'share', 'width=500, height=500');
    },
    clickShare() {
      this.shareNaverBand(this.shareData.link, this.shareData.name);
      this.$emit('onShare');
    },
    literal(strings, content, url) {
      let str0 = strings[0];
      let str1 = strings[1];
      return `${str0}${content}${str1}${url}`;
    }
  }
};
</script>

<style lang="stylus" scoped>
.btn-txt
  color rgb(255, 255, 255)
  font-size 14.25px
  width 100%
  text-align center
.icon-style
  width 60px
  height 60px
.icon-style-m
  width 52px
  height 52px

</style>
