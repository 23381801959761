<template>
<div>
  <div id="kakao-link" @click="clickShare" class="unselect">
    <img src="/static/icon/ic_kakao_circle.svg">
    <div class="size-12 sub1 text-center margin-top-8">카카오톡</div>
  </div>
</div>
</template>

<script>
  export default {
    name: "BtnShareKakao",

    props: {
      shareData: {
        type: Object,
        required: false
      }
    },

    mounted() {
      this.$nextTick(() => {
        // 카카오톡 공유버튼 초기화
        // eslint-disable-next-line no-undef
        if (!Kakao.isInitialized()) {
          // eslint-disable-next-line no-undef
          Kakao.init('c0aa86663ec6ad623a076503b8b009d6');
        }

        let btn = [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: this.shareData.link,
              webUrl: this.shareData.link
            }
          }
        ];

        // 카카오 링크
        // eslint-disable-next-line no-undef
        Kakao.Link.createDefaultButton({
          container: '#kakao-link',
          objectType: 'feed',
          content: {
            title: this.shareData.name,
            description: this.shareData.desc,
            imageUrl: this.shareData.img,
            link: {
              mobileWebUrl: this.shareData.link,
              webUrl: this.shareData.link
            }
          },
          buttons: btn
        });

      });
    },

    methods: {
      clickShare() {
        this.$emit('onShare');
      }
    },
  }
</script>

<style lang="stylus" scoped>

</style>
